import axios from 'axios'
import Element from 'element-ui'
import router from '../../router/index'
// import Cookies from 'js-cookie'

// const baseURL = 'http://campus.x-x-mo.com/'

axios.defaults.baseURL = '/api/admin/api/v2'
// axios.defaults.withCredentials = true

// 请求拦截器
axios.interceptors.request.use(function (config) {
    // 如果需要发送请求之前处理，在这添加
    return config
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
})

// 响应拦截器
axios.interceptors.response.use(function (response) {
    // 对全局的响应数据做处理
    console.log(response)
    if (response.status === 200) {
        if (response.data.code === 201) {
            console.log()
            Element.Message.error(response.data.msg)
            throw new Error('错误')
        }
        if (response.data.code === 202) {
            Element.Message.error('登录失效，请重新登录！')
            router.push('/login')
        }
        if (response.data.code === 200) {
            return response.data
        }
    }
}, function (error) {
    // 对响应错误做点什么
    console.log('--- 401 res error response ---')
    console.log(error.response)
    if (error && error.response && error.response.status === '401') {

    }
    return Promise.reject(error)
})

export default axios
