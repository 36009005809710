import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/login.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "admin" */ '../views/admin/admin.vue'),
                meta: { keepAlive: true } // 开启路由缓存
            },
            {
                path: 'admin',
                component: () => import(/* webpackChunkName: "admin" */ '../views/admin/admin.vue'),
                meta: { keepAlive: true } // 开启路由缓存
            },
            {
                path: 'config',
                component: () => import(/* webpackChunkName: "config" */ '../views/config/config.vue')
            },
            {
                path: 'serviceConfig',
                component: () => import(/* webpackChunkName: "config" */ '../views/config/serviceConfig.vue')
            },
            {
                path: 'courier',
                component: () => import(/* webpackChunkName: "courier" */ '../views/courier/courier.vue')
            },
            {
                path: 'courier/add/:isEdit',
                component: () => import(/* webpackChunkName: "courier" */ '../views/courier/addCourier.vue')
            },
            {
                path: 'rider',
                component: () => import(/* webpackChunkName: "rider" */ '../views/rider/rider.vue')
            },
            {
                path: 'phonebook/list',
                component: () => import(/* webpackChunkName: "phonebook" */ '../views/phoneBook/phoneBook.vue')
            },
            {
                path: 'phonebook/type',
                component: () => import(/* webpackChunkName: "phonebook" */ '../views/phoneBook/phoneBookType.vue')
            },
            {
                path: 'order',
                component: () => import(/* webpackChunkName: "order" */ '../views/order/order.vue')
            },
            {
                path: 'car',
                component: () => import(/* webpackChunkName: "car" */ '../views/car/car.vue')
            },
            {
                path: 'car/add/:isEdit',
                component: () => import(/* webpackChunkName: "car" */ '../views/car/addCar.vue')
            },
            {
                path: 'goods',
                component: () => import(/* webpackChunkName: "goods" */ '../views/goods/goods.vue')
            },
            {
                path: 'goods/add/:isEdit',
                component: () => import(/* webpackChunkName: "goods" */ '../views/goods/addGoods.vue')
            },
            {
                path: 'goods/type',
                component: () => import(/* webpackChunkName: "goods" */ '../views/goods/goodsType.vue')
            },
            {
                path: 'store',
                component: () => import(/* webpackChunkName: "store" */ '../views/store/store.vue')
            },
            {
                path: 'store/storeCounter',
                component: () => import(/* webpackChunkName: "store" */ '../views/store/storeCounter.vue')
            },
            {
                path: 'store/user',
                component: () => import(/* webpackChunkName: "store" */ '../views/store/storeUser.vue')
            },
            {
                path: 'store/goodsType',
                component: () => import(/* webpackChunkName: "store" */ '../views/store/storeGoodsType.vue')
            },
            {
                path: 'store/static',
                component: () => import(/* webpackChunkName: "store" */ '../views/store/statistics.vue')
            },
            {
                path: 'school',
                component: () => import(/* webpackChunkName: "school" */ '../views/school/school.vue')
            },
            {
                path: 'school/pickupPoint',
                component: () => import(/* webpackChunkName: "school" */ '../views/school/pickupPoint/pickupPoint.vue')
            },
            {
                path: 'data/dict',
                component: () => import(/* webpackChunkName: "goods" */ '../views/goods/dataDict.vue')
            },
            {
                path: 'dynamo',
                component: () => import(/* webpackChunkName: "dynamo" */ '../views/dynamo/dynamo.vue')
            },
            {
                path: 'dynamo/add/:isEdit',
                component: () => import(/* webpackChunkName: "dynamo" */ '../views/dynamo/addDynamo.vue')
            },
            {
                path: 'coupon',
                component: () => import(/* webpackChunkName: "coupon" */ '../views/coupon/coupon.vue')
            },
            {
                path: 'coupon/add/:isEdit',
                component: () => import(/* webpackChunkName: "coupon" */ '../views/coupon/addCoupon.vue')
            },
            {
                path: 'feedbook',
                component: () => import(/* webpackChunkName: "feedback" */ '../views/feedback/feedback.vue')
            }
        ]
    },
    {
        path: '*', // 未匹配到的路由重定向到首页
        redirect: '/'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 } // 滚动行为，每次切换路由时，都将页面滚动到顶部
    }
})

export default router
